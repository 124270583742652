import React from "react";
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AuthContext from "./services/AuthContext";
import Feedback from "./pages/index";
// import DealView from "./pages/view";
// import DealEdit from "./pages/edit";
// import DealCreate from "./pages/create";
// import ProductPage from "./pages/product";
// import DealsConfigIndex from "./pages/deal-config/index";
// import ConfigView from "./pages/deal-config/ConfigView";
// import ConfigProduct from "./pages/deal-config/ConfigProduct";


export default function Root(props) {
  console.log("Root props" , props)
  let context = { ...props };

  return (
    <AuthContext.Provider value={{ ...props }}>
      <Router>
        <Route
          render={({ location }) => (
            <TransitionGroup className="transition-group">
              <CSSTransition key={location.key} timeout={300} classNames="fade">
                <section className="route-section">
                  <Switch location={location}>
                    <Route path="/location" exact component={Location} /> 

                    {props.auth.access["feedback"]["A"] &&
                      props.auth.access["feedback"]["R"] && (
                        <Route
                          path="/feedback/index"
                          exact
                          component={(route) => (
                            <Feedback
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )}

                    {/* {props.auth.access["apps"]["A"] &&
                      props.auth.access["apps"]["R"] && (
                        <Route
                          path="/apps/view/:id"
                          exact
                          component={(route) => (
                            <DealView
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )}
                    {props.auth.access["apps"]["A"] &&
                      props.auth.access["apps"]["C"] && (
                        <Route
                          path="/apps/create"
                          exact
                          component={(route) => (
                            <DealCreate
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )}
                    {props.auth.access["apps"]["A"] &&
                      props.auth.access["apps"]["U"] && (
                        <Route
                          path="/apps/edit/:id"
                          exact
                          component={(route) => (
                            <DealEdit
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )}

                      {props.auth.access["apps"]["A"] &&
                      props.auth.access["apps"]["R"] && (
                        <Route
                          path="/apps/product/:id"
                          exact
                          component={(route) => (
                            <ProductPage
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )}

                     

                      {props.auth.access["apps"]["A"] &&
                      props.auth.access["apps"]["R"] && (
                        <Route
                          path="/apps/index"
                          exact
                          component={(route) => (
                            <DealsConfigIndex
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )}

                    {props.auth.access["apps"]["A"] &&
                      props.auth.access["apps"]["R"] && (
                        <Route
                          path="/apps/view/:id"
                          exact
                          component={(route) => (
                            <ConfigView
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )}

                    {props.auth.access["apps"]["A"] &&
                      props.auth.access["apps"]["R"] && (
                        <Route
                          path="/apps/product/:id"
                          exact
                          component={(route) => (
                            <ConfigProduct
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )} */}

                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </AuthContext.Provider>
  );
}
