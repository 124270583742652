import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { FusionAuthApi, AppHeaderComponent, ApiLoaderComponent } from "@unity/components";
import { CanCreate, CanModuleAdmin } from "../services/Permissions";
import ModuleFunctions from "../modules/ModuleFunctions";
import "./appStyles.css";
import ModuleButton from "../common/ModuleButton";
import { InputLabel, MenuItem, Select, TextField } from "@mui/material";


export default function Feedback(props) {

  const [data, setData] = useState({});
  const [loading, setLoading] = useState({ status: false, data: false });

  //change the permission to feedback once I have permission, and change in the app header
  const create = CanCreate("feedback");
  const admin = CanModuleAdmin("feedback");

  const relatedSystems = [
    { id: 1, name: "Portal" },
    { id: 2, name: "TimeSheet" },
    { id: 3, name: "Marketing" },
    { id: 4, name: "Sales" },
    { id: 5, name: "Support" },
    { id: 6, name: "Contract" },
    { id: 7, name: "Purchasing" },
    { id: 8, name: "ABC / Job Costing" },
    { id: 9, name: "Fleet" },
    { id: 10, name: "Profile" },
    { id: 11, name: "Customers" },
  ];

  const typeOptions = [
    { id: 1, name: "Bug" },
    { id: 2, name: "Improvement / Addition" },
    { id: 3, name: "Investigation" },
  ];

  return (
    <>
      <AppHeaderComponent
        context={props.context}
        theme={props.context.theme}
        name={"feedback"}
        spacer={true}
      />

      <div className="container">

        <div className="block">
          <div className="form-row">
            <div className="form-group col-lg-6">
              <InputLabel>Related System</InputLabel>
              <Select
                fullWidth
                name="related_system"
                value={data.related_system ? data.related_system : ""}
                onChange={(e) =>
                  setData({ ...data, related_system: e.target.value })
                }
              >
                {relatedSystems.map((e, key) => (
                  <MenuItem key={key} value={e.name}>
                    {e.name}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div className="form-group col-lg-6">
              <InputLabel>Type</InputLabel>
              <Select
                fullWidth
                name="related_system"
                value={data.type ? data.type : ""}
                onChange={(e) => setData({ ...data, type: e.target.value })}
              >
                {typeOptions.map((e, key) => (
                  <MenuItem key={key} value={e.name}>
                    {e.name}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div className="form-group col-lg-6">
              <InputLabel>Date / Time Occured: </InputLabel>
              <TextField
                name="date"
                type="datetime-local"
                value={data.date ? data.date : ""}
                onChange={(e) => setData({ ...data, date: e.target.value })}
                fullWidth={true}
              ></TextField>
            </div>
          </div>

          <TextField
            style={{ marginBottom: 20 }}
            rows={10}
            fullWidth
            multiline
            name="description"
            label="Description:"
            value={data.description}
            onChange={(e) => setData({ ...data, description: e.target.value })}
          />

          <div style={{ marginBottom: 10, textAlign: "right" }}>
            <a
              href={encodeURI(
                "mailto:" +
                "support@unity.world" +
                "" +
                "?subject=" +
                data.related_system +
                " " +
                data.type +
                "&body=" +
                data.description +
                " " +
                "Date: " +
                data.date
              )}
            >
              <ModuleButton
                text="submit feedback"
                btnIcon="save"
                style={{ color: "white", background: "blue" }}
              />
            </a>
          </div>

          <ApiLoaderComponent
            status={loading.status}
            data={loading.data}
          />
        </div>
      </div>

    </>
  );
}
